export const contacts = [
  {
    title: 'Телефон фонда в г. Алматы:',
    text: '+7 708 086 88 65',
  },
  // {
  //   title: 'Телефон филиала в г. Нур-Султан:',
  //   text: '+7 708 986 88 65',
  // },
  // {
  //   title: 'Телефон филиала в г. Семей:',
  //   text: '+7 708 703 88 65',
  // },
  {
    title: 'Адрес фонда',
    text: 'Республика Казахстан, 050000, г. Алматы, проспект Жибек жолы, 135',
  },
];
