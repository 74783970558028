import axios from 'axios';

export const baseURL = process.env.REACT_APP_API;

const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

if (localStorage.getItem('token')) {
  instance.defaults.headers.common['Authorization'] = `Token ${localStorage.getItem('token')}`;
}

export function getHeader(name) {
  return instance.defaults.headers.common[name];
}

export function updateHeader(name, value) {
  instance.defaults.headers.common[name] = value;
}

export default instance;
