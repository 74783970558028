import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

import kz from 'languages/kk-KZ.json';
import ru from 'languages/ru-RU.json';
import en from 'languages/en-EN.json';

const LANGUAGES = {
  KAZ: 'KAZ',
  RU: 'RU',
  EN: 'EN',
};

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageContextProvider = ({ children }) => {
  const [lang, setLang] = useState(ru);
  const [currentLang, setCurrentLang] = useState(LANGUAGES.RU);

  const changeLang = useCallback((langString) => {
    if (Object.values(LANGUAGES).includes(langString)) {
      if (langString === LANGUAGES.KAZ) {
        setLang(kz);
        setCurrentLang(LANGUAGES.KAZ);
      } else if (langString === LANGUAGES.EN) {
        setLang(en);
        setCurrentLang(LANGUAGES.EN);
      } else {
        setLang(ru);
        setCurrentLang(LANGUAGES.RU);
      }
      localStorage.setItem('lang', langString);
    }
  }, []);

  const handleAges = useCallback((age) => {
    const lang = localStorage.getItem('lang') || LANGUAGES.RU;

    if (lang === LANGUAGES.RU || lang === LANGUAGES.KAZ) {
      if (lang === LANGUAGES.RU) {
        if (age === 1) {
          return `${age} год`;
        }
        if (age % 10 === 1 && age !== 11) {
          return `${age} год`;
        }
        return `${age} лет`;
      } else if (lang === LANGUAGES.KAZ) {
        return `${age} жас`;
      }
    } else if (lang === LANGUAGES.EN) {
      if (age === 1) {
        return `${age} year`;
      }
      return `${age} years`;
    }
  }, []);

  useEffect(() => {
    const langString = localStorage.getItem('lang') || process.env.REACT_APP_LANGUAGE;
    if (langString && Object.values(LANGUAGES).includes(langString)) {
      changeLang(langString);
    }
  }, [changeLang]);

  return (
    <LanguageContext.Provider value={{ lang, changeLang, LANGUAGES, currentLang, handleAges }}>
      {children}
    </LanguageContext.Provider>
  );
};
