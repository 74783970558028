export const SET_USER = 'AUTH/SET_USER';
export const SET_TOKEN = 'AUTH/SET_TOKEN';

export const setUser = (user) => (dispatch) => {
  localStorage.setItem('user', JSON.stringify(user));
  dispatch({
    type: SET_USER,
    payload: user,
  });
};

export const setToken = (token) => (dispatch) => {
  localStorage.setItem('token', token);
  dispatch({
    type: SET_TOKEN,
    payload: token,
  });
};
