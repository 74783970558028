import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Toaster } from 'sonner';

import LoadingPage from 'app/shared/loading-page/LoadingPage';
import ScrollToTop from 'app/shared/scroll-to-top/ScrollToTop';
import Header from 'app/shared/header/Header';
import Footer from 'app/shared/footer/Footer';
import { LanguageContextProvider } from './language-context';

import './App.scss';

import { connect } from 'react-redux';
import { setCities } from 'state/actions/utility.actions';
import { setPrograms, setDefaultDenomations } from 'state/actions/donations.actions';

import { getCities } from 'services/utility.service';
import { getPrograms, getDefaulDenominations } from 'services/donations.service';

import { setToken, setUser } from 'state/actions/auth.actions';

const AuthLayout = React.lazy(() => import('./pages/auth/Auth'));
const InfoLayout = React.lazy(() => import('./pages/info/Info'));
const MainLayout = React.lazy(() => import('./pages/main/Main'));
const PrivacyPolicy = React.lazy(() => import('../constants/privacy-policy/PrivacyPolicy'));
const PublicOffer = React.lazy(() => import('../constants/PublicOffer'));

const App = ({ setCities, setPrograms, setDefaultDenomations, setToken, setUser }) => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    if (token) {
      setToken(token);
    }
    if (user) {
      setUser(user);
    }

    getCities().then((response) => {
      setCities(response.data);
    });

    getPrograms().then((response) => {
      setPrograms(response.data);
    });

    getDefaulDenominations().then((response) => {
      setDefaultDenomations(response.data);
    });
  }, [setCities, setPrograms, setDefaultDenomations, setToken, setUser]);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Suspense fallback={<LoadingPage />}>
          <LanguageContextProvider>
            <Header />
            <main className="main">
              <Switch>
                <Route path="/auth" component={AuthLayout} />
                <Route path="/info" component={InfoLayout} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/public-offer" component={PublicOffer} />
                <Route path="/" component={MainLayout} />
              </Switch>
            </main>
            <Footer />
            <Toaster richColors />
          </LanguageContextProvider>
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default connect(null, { setCities, setPrograms, setDefaultDenomations, setToken, setUser })(App);
